/* Locally Imported Fonts */
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../../components/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../../../components/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: medium;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../../components/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

/* Main Container */
.user-main-popup-form-container {
    display: flex;
    justify-content: center;
    height: auto;
}

.popup-form-container {
    display: flex;
    flex-direction: column;
    width:auto;
    height: auto;
    border-radius: 5px;
    border: 1px solid #00000033;
    background-color: #EFEFEF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #00000033;
    max-height: 100%; /* Adjust the maximum height as needed */

}



/* Popup Form - Edit Header */
.edit-popup-form-header {
    display: flex;
    background-color:#F5CA62;;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #00000033;
    width: 100%;
}

.view-popup-form-header {
    display: flex;
    background-color: #47B8B2;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #00000033;
    width: 100%;
}

.edit-popup-title-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #7a7878;
    padding: 5px 15px;
    width: 100%;
}

.epop-header-icon {
    padding: 10px 0px;
    margin: 0;
}

.epop-header-icon i {
    font-size: 26px;
    color: white;
    cursor: pointer;
}

.epop-header-title p {
    font-size: 20px;
    font-family: 'Roboto-Bold';
    margin: 0;
}


.user-det-container{
    margin:0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    width: 100%;
    align-items: center;
}


/* User Registration Container */
.user-details-popup-container {
    display: flex;
    flex-direction: row;
}

/* User Registration Upload Photo */
.left-profile-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 5px 20px;
    margin-top: 40px;
    align-items: center;
}

.profile-container {
    position: relative;
    overflow: hidden;
    width: 150px;
    height: 150px;
    cursor: pointer;
    border-radius: 5px;
    border: 10px solid #e4e4e4;
    margin-bottom: 5px;
  }

  .view-profile-container {
    position: relative;
    overflow: hidden;
    width: 150px;
    height: 150px;
    border-radius: 5px;
    border: 10px solid #e4e4e4;
    margin-bottom: 5px;
  }
  
  .profile-container .stud-profile {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .profile-container .upload-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); 
    display: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .upload-det{
    display: flex;
    flex-direction: column;
    font-size: 28px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .profile-container:hover .upload-icon {
    opacity: 1; 
  }
  
  .ui-txt{
    font-family: "Roboto-Regular";
    font-size: 14px;
    width:120px;
  }
    




/* Profile Picture */
.view-user-upload-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}


.view-user-upload-main-container img.stud-profile {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    object-fit: cover; 
}



.view-user-upload-main-container button {
    font-family: "Roboto-Medium";
    font-size: 12px;
    width: 100%;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 0;
    margin: 0px;
    cursor: pointer;
}



/* Upload File Container */
.edit-profile-ff input[type="file"] {
    font-size: 12px;
    font-family: "Roboto-Regular";
    background-color: #EFEFEF;
    border-radius: 5px;
    border: none;
    padding: 10px;
    width:150px;
}

.profile-update-btn{
    display: flex;
    justify-content: center;
    width:100%;
    font-family: "Roboto-Medium";
    font-size: 12px;
    text-decoration: none;
    padding: 10px;
    margin-bottom: 5px;
    width: 150px;
    background-color: #188ABB;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.profile-update-btn:hover{
    background-color: #14759e;
}

.profile-cancel-btn{
    display: flex;
    justify-content: center;
    width:100%;
    font-family: "Roboto-Medium";
    font-size: 12px;
    text-decoration: none;
    padding: 10px;
    margin-bottom: 5px;
    width: 150px;
    background-color: #647e8a;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.profile-cancel-btn:hover{
    background-color: #497183;
}

.profile-download-btn{
    display: flex;
    justify-content: center;
    width:100%;
    font-family: "Roboto-Medium";
    font-size: 12px;
    text-decoration: none;
    margin-bottom: 5px;
    padding:10px;
    width: 150px;
    background-color: #188ABB;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.profile-download-btn:hover{
    background-color: #126d94;
}

.qr-download-btn{
    display: flex;
    justify-content: center;
    width:100%;
    font-family: "Roboto-Medium";
    font-size: 12px;
    padding: 10px;
    width: 150px;
    background-color: #47B8B2;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
   
}

.qr-download-btn{
    background-color: #40aaa5;
}

/* QR Code Container */
.qr-code-main-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.qr-user-container {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    border: 10px solid #8b8b8b33;
}

/* User Data Right Portion */

.user-info-right {
    display: flex;
    flex-direction: column;
    margin:10px 0px;
    padding: 5px;
    width: 85%;
    height: 480px;
    overflow-y: scroll;
}


/* User Information Subheaders */
.popup-subheader-container {
    width: 100%;
}

.popup-subheader-container p {
    font-family: "Roboto-Bold";
    font-size: 16px;
    color: #37aaa4;
    margin: 0;
    padding: 10px 5px 5px 10px;
}

/* User Basic Information Form Fields */
.popup-basic-info-ff-container,
.popup-user-account-info-ff-container,
.popup-emergency-information-ff-container {
    padding: 0 10px 0px 10px;
}


/* Form Field CSS - General */
.popup-info-ff-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.popup-info-ff-2,
.popup-info-ff-3,
.popup-info-ff-4,
.popup-info-ff-5,
.popup-info-ff-6 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.popup-info-form-field input,
.popup-info-form-field select {
    font-size: 12px;
    font-family: "Roboto-Regular";
    background-color: #EFEFEF;
    border-radius: 5px;
    border: none;
    padding: 10px;
    margin-right: 5px;
}

.popup-info-form-field-sn input {
    font-size: 12px;
    font-family: "Roboto-Regular";
    background-color: #EFEFEF;
    border-radius: 5px;
    border: none;
    padding: 10px;
    margin-right: 5px;
    width: 100px;
}

.popup-info-form-field-sn input:focus {
    outline: 1px solid #47B8B2;
    border: 1px solid #47B8B2;
}
/* Drop Down */

.popup-info-form-field option {
    font-size: 12px;
    font-family: "Roboto-Regular";
    color: #525252;
    background-color: #EFEFEF;
    border: none;
    margin-right: 5px;
    height: 30px;
}

.popup-info-form-field,.popup-info-form-field-sn,
.popup-info-form-field-ext label {
    font-family: "Roboto-Medium";
    font-size: 12px;
    padding: 2.5px 0;
    color: #525252;
}

.popup-info-form-field {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.popup-info-form-field input:focus {
    outline: 1px solid #47B8B2;
    border: 1px solid #47B8B2;
}

.popup-info-form-field select:focus {
    outline: 1px solid #47B8B2;
    border: 1px solid #47B8B2;
}

.popup-info-form-field-ext {
    display: flex;
    flex-direction: column;
    width: 100px;
}

.popup-info-form-field-ext input {
    font-size: 12px;
    font-family: "Roboto-Regular";
    background-color: #EFEFEF;
    border-radius: 5px;
    border: none;
    padding: 10px;
    margin-right: 5px;
}

.popup-info-form-field-ext input:focus {
    outline: 1px solid #47B8B2;
    border: 1px solid #47B8B2;
}

.ast {
    font-size: 16px;
    color: rgb(190, 50, 50);
}

.important-ff {
    display: flex;
    align-items: center;
}

.important-ff p {
    margin: 0;
    padding-left: 2px;
}

/* Create User Button */
.popup-bottom-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: auto;
}

.update-btn-container {
    margin-right: 15px;
}

.update-user-btn {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px 30px;
    background-color: #409B54;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    width: 100%;
    border: none;
    font-family: "Roboto-Medium";
    font-size: 12px;
}

/* Modal Overlay */
.qr-popup-overlay{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

/* Upload File */
.batch-upload{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 400px;
    height:125px;
}

.batch-title{
    display: flex;
    align-items: center;
    margin-left: 20px;
    width: 100%;
    font-family: "Roboto-Medium";
    font-size: 16px;
    color: #ffffff;
}

.batch-det{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: "Roboto-Light";
    font-size: 12px;
    margin:5px 0px;
    color: #525252;
}


.batch-upload-file-body input{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #00000033;
    padding: 5px;
    font-family: "Roboto-Regular";
    font-size: 14px;
}

.batch-btn{
    background-color: #37aaa4;
    color: #fff;
    font-family: "Roboto-Regular";
    font-size: 14px;
    border-radius: 5px;
    padding: 5px 10px;
}

.batch-btn:hover{
    background-color: #32807c;
    cursor: pointer;
}

/* Loading Animation */
.loading-overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    color: #fff;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;

}

.loading-icon-animation {
    position: static;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    height: 30px;
    width: 30px;
    border: 6px solid transparent;
    border-top-color: #47B8B2;
    animation: border-animation 2s linear infinite, background-animation 2s linear infinite;
    z-index: 0;
    /* Ensure animation is behind the icon */
}

.loading-text {
    font-family: "Roboto-Regular";
    font-size: 14px;
    margin-top: 20px;
    opacity: 0;
    /* Start with text hidden */
    animation: fadeIn .5s ease-in-out forwards;
    /* Apply the fade-in animation */
}

@keyframes border-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes background-animation {

    0%,
    100% {
        background-color: transparent;
    }

    50% {
        background-color: #47B8B2;
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
        /* Start with text hidden */
    }

    100% {
        opacity: 1;
        /* Fully visible at the end of the animation */
    }
}