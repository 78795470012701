/*Locally Imported Fonts*/
@font-face {
    font-family: 'SSPro-Bold';
    src: url('../../../components/fonts/source-sans-pro/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('../../../components/fonts/Inter/Inter-Medium.ttf') format('truetype');
    font-weight: medium;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../../components/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../../../components/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: medium;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../../components/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}


/*Main*/
.body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height:100vh;
    width: 100%;
}

/*Left Side*/

.left_loginformwrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
}

.uniid_logo{
    padding: 5% 0 0 5%;
    
}

.logo{
    height: 50px;
}
/*Main LogIn Form Container With Header*/
.left_loginform{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


/*Header LogIn Form Container*/

.header_loginform{
    display: flex;
    flex-direction: column;
    width: 400px;
    height: max-content;

}
.s_login_header{
    height: 25px;
    flex-direction: column;
    color: #404040;
    font-family: 'SSPro-Bold';
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    margin: 0px;
}

.s_login_cap{
    display: flex;
    height: 5px;
    color: #586166;
    font-family: 'Inter-Medium';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
}

/*Staff LogIn Details*/

.s_login_det{
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    width: 400px;
    margin:10px 0px;
}

.staff-ff{
    width:400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.form-field{
    display: flex;
    flex-direction: column;
}

.form-field input{
    font-size: 14px;
    font-family: "Roboto-Regular";
    background-color: #EFEFEF;
    border-radius:5px;
    border:none;
    padding: 15px;
}

.form-field label{
    font-family: "Roboto-Regular";
    font-size: 14px;
    margin-bottom: 5px;
}

.form-field-s-pass {
    display: flex;
    flex-direction: column;
    position: relative; 
    font-family: "Roboto Regular";
    font-size: 14px;
    color: #586166;
    margin:5px 0px;
}

.form-field-s-pass label{
    font-family: "Roboto-Regular";
    font-size: 14px;
    margin-bottom: 5px;
}

.form-field-s-pass input {
    font-size: 14px;
    font-family: "Roboto Regular";
    background-color: #EFEFEF;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 15px;
    width: 100%;
    padding-right: 50px;
}

.form-field-s-pass input:focus {
    outline: 1px solid #47B8B2;
    border: 1px solid #47B8B2;
}

.ff-s-pass {
    display: flex;
    flex-direction: row;
    position: relative; /* Add position relative to establish a stacking context */
}
.S_showBtn {
    position: absolute; /* Position the button absolutely within the input container */
    top: 50%; /* Vertically center the button */
    right: 15px; /* Adjust the distance from the right edge */
    transform: translateY(-50%); /* Center the button vertically using translate */
    color: #47B8B2;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
    font-family: "Roboto Medium";
}

.staff-login-error{
    display: flex;
    font-family: "Roboto-Medium";
    font-size:14px;
    color: #D87575;
    margin: 5px 0px;
}


/*Forgot Password*/

.fgp_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:400px;
    height: 30px;
    font-family: "Roboto-Medium";
    font-size: 14px;
}

/* Style the custom checkbox container */
.kms {
    display: flex;
    align-items: center;
    color: #333;
    cursor: pointer; 
}


.kms .cb[type="checkbox"] {
    display: none; 
}


.kms .cm {
    position: relative;
    padding: 7px;
    border: 2px solid #dbdbdb; 
    background-color: transparent;
    margin-right: 10px; 
}

/* Style the custom checkbox when checked */ 
.kms .cb[type="checkbox"]:checked + .cm {
    background-color: #47B8B2;
    border-color: #47B8B2;
}

/* Add a checkmark icon when the checkbox is checked */
.kms .cb[type="checkbox"]:checked + .cm::before {
    content: "\2713"; /* Unicode checkmark character (✓) */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 14px;
    border-radius: 5px;
}

.fps{
    text-decoration: none;
    color: #47B8B2;
}

/*LogIn Button*/
.login_btn{
    margin-top: 8px;
    margin-bottom: 10px;
    padding: 0.7rem 9.25rem;
    background-color: #47B8B2;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    width: 100%;
    border: none;
    font-family: "Roboto-Medium";
    font-size: 14px;
    }


/*Right Side*/

.staff_img{
    display: flex;
    width: 100%;
    height: 100vh;
    background-image: url('../../../assets/staff_banner.png');
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 1024px){
    .staff_img{
        background-position:center;
    }
}


@media (max-width: 768px){
    .staff_img{
        display:none;
    }
}

@media (max-width: 425px) {
    .staff_img{
        display:none;
    }

    .uniid_logo {
        padding: 25px 0 0 30px;
    }

    .logo{
        height:40px;
    }
    .form-field label{
        text-align:left;
    }

    .form-field label, .form-field-s-pass label {
        font-family: "Roboto-Regular";
        font-size: 14px;
        margin-bottom: 5px;
    }


    .header_loginform {
        display: flex;
        flex-direction: column;
        width: 350px;
        height: max-content;
    }

    .s_login_header {
        height: 25px;
        font-size: 26px;
        font-style: normal;
    }

    .s_login_cap {
        display: flex;
        height: 5px;
        color: #586166;
        font-family: 'Roboto-Medium';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        margin:5px 0px;
    }



    .s_login_det {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        width: 350px;
    }

    .staff-ff {
        width: 350px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

@media (max-width: 375px) {
    .staff_img{
        display:none;
    }

    .uniid_logo {
        padding: 25px 0 0 30px;
    }

    .logo{
        height:40px;
    }
    .form-field label{
        text-align:left;
    }

    .header_loginform {
        display: flex;
        flex-direction: column;
        width: 300px;
        height: max-content;
    }

    .s_login_det {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        width: 300px;
        margin: 10px 0px;
    }

    .staff-ff {
        width: 300px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .login_btn {
        padding: 10px;
    }
    

}

