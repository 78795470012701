/* Locally Imported Fonts */
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../../components/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../../../components/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: medium;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../../components/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

@font-face {
    font-family: 'SSPro-Bold';
    src: url('../../../components/fonts/source-sans-pro/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

/* User Main Container */

.user-body-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
    overflow-x: hidden;
}

/* User Contents */

.user-homepage-main-contents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    height: 100%;
}


.user-homepage-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.user-body-limiter-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.user-body-limiter {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 10px 30px;
  height: 100%;
  width: 1440px;
} 

.user-body-container{
    width: 100%;
    height: 100%;
}

.user-homepage-contents{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

/* Lower Topbar  */

.lower-topbar-wrapper{
    display: flex;
    justify-content: center;
    background-color: #47B8B2;

}

.lower-topbar{
    font-family: "Roboto-Regular";
    font-size: 14px;
    color: #FFFFFF;
    padding: 10px;
    display: flex;
    flex-direction: row;
    padding: 15px 35px;
    height: 100%;
    width:1440px;
}

.lower-topbar-text{
    padding: 0px 5px;
}

.top-linking-page{
    text-decoration: none;
}

.active-linking{
    color: #ffff;
}

.active-linking:hover{
    color: #ffce74;
}
/* Announcement Details Container */
.anno-postview-container-wrapper{
    display: flex;
    justify-content: center;
    height: fit-content;
    overflow-y: auto;
}

.annoview-post-main-container{
    margin: 15px;
    padding: 15px;
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 1px solid #00000033;
    overflow-y: auto;
}


/* Announcement Details */

.anno-post-contents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    width:700px;
}
.anno-picture-container{
    display: flex;
    justify-content: center;
    width:auto;
    height: auto;
    border-radius: 10px;
}
.anno-main-picture{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width:700px;
    height:400px;
    border-radius: 10px;
    background-size: cover;
}



/* Announcement Main Body */
.annodet-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.annodetails-container{
    display: flex;
    flex-direction:column;
    justify-content: center;
    width:700px;
    padding: 5px;
}

.anno-main-title-txt{
    font-family: "SSPro-Bold";
    font-size: 30px;
    color: #181818;
}

.anno-poster-details{
    font-family: "Roboto-Medium";
    font-size: 14px;
    color: #181818;
    
}

.anno-poster{
    margin: 5px 0px;
    margin-right: 15px;
}

.anno-post-det-main-container{
    margin-top: 5px;
    text-align: justify;
    padding-bottom: 40px;
}

.anno-post-det{
    font-family: "Roboto-Regular";
    color: #6C6C6C;
    text-align: center;
}

.user-search-container {
    display: flex;
    justify-content: center;
    width: 350px;
    margin: 15px 0px;
}
