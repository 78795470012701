/* Locally Imported Fonts */
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../components/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../components/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: medium;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../components/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

@font-face {
    font-family: 'SSPro-Bold';
    src: url('../components/fonts/source-sans-pro/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

/* Body */

.error-body{
    display: flex;
    width:100%;
    height: 100vh;
}

.error-contents{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.err-det{
    font-family: "SSPro-Bold";
    font-size: 48px;
    width: 500px;
    height: 300px;
}

.error-404-cont{
    background-image: url(../assets/error404.png);
    background-size:cover ;
    background-position: center;
    width:500px;
    height: 300px;
}