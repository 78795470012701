/* Locally Imported Fonts */
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../../components/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../../../components/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: medium;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../../components/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

/* Main Container */
.admin-sreg-container {
    display: flex;
    justify-content: center;
    overflow-y: auto;
    height: auto;
}

.sreg-container {
    margin: 35px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #00000033;
}

/* User Account Registration Header */
.sreg-form-header {
    display: flex;
    background-color: #ffff;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #00000033;
}

.sreg-title-container {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    color: #525252;
    padding: 5px 15px;
}

.back-btn-page{
    display: flex;
    text-decoration: none;
}

.sreg-header-icon {
    padding: 10px;
    margin: 0;
    cursor: pointer;
}

.sreg-header-icon :hover{
    color:#37aaa4;
}

.sreg-header-icon i {
    font-size: 24px;
    color: #525252;
}

/* Tooltip Text */
.sreg-title-container .back-btn-page .back-tooltip {
    visibility: hidden;
    font-family: "Roboto-Medium";
    font-size: 12px;
    background-color: #444444;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px 10px;
    width: fit-content;
    position: absolute;
    z-index: 1;
    bottom: 70%; 
    left: 20%;
    margin-left: -15px; 
    opacity: 0;
    transition: opacity 0.2s;
  }
  
  /* Show the Tooltip on Hover */
  .sreg-title-container .back-btn-page:hover .back-tooltip {
    visibility: visible;
    opacity: 1;
  }

.sreg-header-title p {
    font-size: 20px;
    font-family: 'Roboto-Bold';
    margin: 0;
}






/* User Registration Container */
.staff-reg-input-container {
    display: flex;
    flex-direction: row;
}

/* User Registration Upload Photo */
.sreg-upload-profile-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px 35px;
    justify-content: center;
}

.sreg-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border-radius: 100px;
    height: 120px;
    width: 120px;
    border: 15px solid #d8d8d8;
}


.staff-upload.main.container img {
    width: 200px;
    height: 200px;
    border-radius: 5px;
}

.staff-upload.main.container{
    display: flex;
    justify-content: center;
    margin: 5px;
}

.staff-upload.main.container button {
    font-family: "Roboto-Medium";
    font-size: 14px;
    width: 100%;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 15px 0;
    margin: 15px 0;
    cursor: pointer;
}



.s-reg-form-field input[type="file"] {
    font-size: 14px;
    font-family: "Roboto-Regular";
    background-color: #EFEFEF;
    border-radius: 5px;
    border: none;
    padding: 15px;
    margin-left: 5px;
    width:170px;
}


/* User Registration Subheaders */
.subheader-container {
    width: 100%;
}

.subheader-container p {
    font-family: "Roboto-Bold";
    font-size: 18px;
    color: #37aaa4;
    margin: 10px 5px 5px 0px;
    padding: 10px 5px 10px 10px;
}

/* User Basic Information Form Fields */
.basic-info-ff-container,
.staff-account-info-ff-container,
.emergency-information-ff-container {
    padding: 0 30px 0px 10px;
}

.s-basicinformation {
    padding: 5px;
    width: 95%;
}

/* Form Field CSS - General */
.staff-reg-ff-1 {
    display: flex;
    flex-direction: row;
}

.staff-reg-ff-2,
.staff-reg-ff-3,
.staff-reg-ff-4,
.staff-reg-ff-5,
.staff-reg-ff-6,
.staff-reg-ff-7 {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
}

.s-reg-form-field input,
.s-reg-form-field select {
    font-size: 14px;
    font-family: "Roboto-Regular";
    background-color: #EFEFEF;
    border-radius: 5px;
    border: none;
    padding: 15px;
    margin-right: 5px;
}

/* Drop Down */

.s-reg-form-field option {
    font-size: 14px;
    font-family: "Roboto-Regular";
    color: #525252;
    background-color: #EFEFEF;
    border: none;
    margin-right: 5px;
    height: 30px;
}

.s-reg-form-field,
.s-reg-form-field-ext label {
    font-family: "Roboto-Medium";
    font-size: 14px;
    color: #525252;
}

.s-reg-form-field {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.s-reg-form-field input:focus {
    outline: 1px solid #47B8B2;
    border: 1px solid #47B8B2;
}

.s-reg-form-field select:focus {
    outline: 1px solid #47B8B2;
    border: 1px solid #47B8B2;
}

.s-reg-form-field-ext {
    display: flex;
    flex-direction: column;
    width: 100px;
}

.s-reg-form-field-ext input {
    font-size: 14px;
    font-family: "Roboto-Regular";
    color: #525252;
    background-color: #EFEFEF;
    border-radius: 5px;
    border: none;
    padding: 15px;
    margin-right: 5px;
}

.s-reg-form-field-ext input:focus {
    outline: 1px solid #47B8B2;
    border: 1px solid #47B8B2;
}

.ast {
    font-size: 16px;
    color: rgb(190, 50, 50);
}

.important-ff {
    display: flex;
    align-items: center;
}

.important-ff p {
    margin: 0;
    padding-left: 2px;
}

/* Create User Button */
.bottom-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.create-btn-container {
    margin-right: 35px;
}

.create-staff-btn {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px 30px;
    background-color: #409B54;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    width: 100%;
    border: none;
    font-family: "Roboto-Medium";
    font-size: 14px;
}
