/* Locally Imported Fonts */
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../../components/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../../../components/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: medium;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../../components/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-ExtraBold';
    src: url('../../../components/fonts/Roboto/Roboto-Black.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}


/* Main Container */
.print-id-popup-container{
    display: flex;
    justify-content: center;
    height: auto;
}

.print-id-form-container {
    display: flex;
    flex-direction: column;
    width:auto;
    height: auto;
    border-radius: 5px;
    border: 1px solid #00000033;
    background-color: #EFEFEF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #00000033;
    max-height: 100%; /* Adjust the maximum height as needed */

}



/* Popup Form - Edit Header */
.print-id-header {
    display: flex;
    background-color: #2E9593;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #00000033;
    width: 100%;
}

.print-id-title-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #7a7878;
    padding: 5px 15px;
    width: 100%;
    height: 40px;
}

.epop-header-icon {
    padding: 10px 0px;
    margin: 0;
}

.epop-header-icon i {
    font-size: 26px;
    color: white;
    cursor: pointer;
}

.epop-header-title p {
    font-size: 20px;
    font-family: 'Roboto-Bold';
    margin: 0;
}

/* Print ID Container */
.print-id-contents{
    display: flex;
    flex-direction: row;
}

.print-ID-container{
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.id-pages-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* Front ID Container */

.front-id-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid #00000033;
    height: 3.37008in; 
    width: 2.12598in;
    margin: 10px;
}

.front-id-contents{
    display: flex;
    flex-direction: row;
    width:100%;
    height: 100%;
}

/* Left Design */
.left-design-container{
    display: flex;
    flex-direction: row;
    
}

.rep-wallpaper{
    display: flex;
    width:35px;
    height:auto;
    margin-right: 5px;
    overflow: hidden;
    object-fit: cover;
}

.rep-wallpaper img{
    width: "100%";
    height: "100%";
    object-fit: cover;
    overflow: hidden;
}

.school-logo-overlap{
    display: flex;
    position: absolute;
    top:105px;
    left: 40px;
    justify-content: center;
    z-index:200;
}

.school-logo-overlap img{
    width:45px;
    border: #fff;
    border: 2px solid #ffff;
    border-radius: 100px;
}


.rep-wallpaper-line{
    display: flex;
    background-color: #2E9593;
    width:5px;
}


/* Right Design */
.right-design-container{
    display: flex;
    flex-direction: column;
    width:161.09px;
    background-image: url('../../../assets/SHA_shadow.png');
    background-size: cover;
}

.school-name-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:30px;
    width: 100%;
}

.school-name-txt{
    display: flex;
    justify-content: center;
    
    height:28px;
}

.school-name-txt img{
    height:28px;
}

.school-address-txt{
    font-family: "Roboto-Regular";
    font-size: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 140px;
    white-space: pre-line;
}

.id-picture-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    width:100%;
    

}

.student-id-picture{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 115px;
    width: 115px;
    background-color: #47B8B2;
    border: 3px solid #f5f5f533;
}

.student-id-picture img{
    height: 110px;
    width:110px;
    border: 2px solid #000000;
}

.student-details-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.id-student-det, .id-stuno, .id-cy-det{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    justify-content: center;
}

.id-stuno, .id-cy-det{
    margin-top: 3px;
}

.sd-label{
    display: flex;
    font-family: "Roboto-Medium";
    font-size: 8px;
    color:#47B8B2;
}

.student-name, .student-number{
    font-family: "Roboto-ExtraBold";
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    color: #252525;
}

.student-course-year{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto-ExtraBold";
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    color: #252525;
}

.student-course{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto-Medium";
    font-size: 8px;
    text-align: center;
    color: #252525;
}

.ay-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
}

.ay-txt{
    margin-right: 3px;
    font-family: "Roboto-Medium";
    font-size: 12px;
    color: #47B8B2;
}

.ay-span{
    font-family: "Roboto-Medium";
    font-size: 12px;
    color: #EFEFEF;
    background-color: #2E9593;
    padding: 1px 3px;
}


/* Back ID Container */
  
.back-id-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid #00000033;
    height: 3.37008in; 
    width: 2.12598in; 
    margin: 10px;
}

.back-id-contents{
    display: flex;
    flex-direction: column;
    width:100%;
    height: 100%;
}

.id-back-details{
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    width:100%;
}

.important-container{
    display: flex;
    flex-direction: column;
    width:100%;
    margin-top: 10px;
}

.impt-det{
    font-family: "Roboto-Regular";
    font-size:6.5px;
    display: flex;
    padding: 3px 10px;
    text-align: center;
}

.back-label{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Roboto-Bold";
    font-size:8px;
    background-color: #161616;
    color: #EFEFEF;
    width: 100%;
    padding: 1px 0px;
}

.top-contact-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:5px;
}

.contact-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:2px;
}

.cont-det{
    display: flex;
    font-family: "Roboto-Bold";
    font-size:10px;
}


.emergency-details-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #161616;
    border-radius: 10px;
    margin: 0px 10px;
    padding:5px;
}

.emphasis-emergency-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Roboto-Bold";
    font-size:8px;
}

.none-emergency-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Roboto-Regular";
    font-size:8px;
}



.qr-details{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.qr-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.qr-code-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 110px;
    height: 110px;
    border: 3px solid #161616;
}

.signatoree-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.e-signature-signatoree{
    height: 5px;
}

.name-signatoree{
    font-family: "Roboto-Bold";
    font-size: 10px;
    text-decoration: underline;
}

.signatoree-position{
    font-family: "Roboto-Medium";
    font-size: 8px;
    margin-bottom: 3px;
    
}

.footer-id{
    display: flex;
    width: 100%;
    height: 15px;
    bottom: 0;
    background-color: #161616;
    
}

.print-id-button{
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
}

.printing-id-btn{
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #d63e3e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    border: none;
    font-family: "Roboto-Medium";
    font-size: 14px;
}



.printing-id-btn i{
    color:white;
    margin-left: 5px;
}