/* Admin General Page CSS */
.admin-u-regpage{
    display: flex;
    overflow: hidden;
}

.admin-sidebar{
    display: flex;

}

.admin-u-reg-contents{
    max-height: 100vh;
    width: 100vw;
    overflow-y:auto;
}


/* Admin Create Announcement Page CSS */

.admin-a-reg-contents{
    max-height: 100vh;
    width: 100vw;
    overflow-y: auto;
}

.admin-anno-contents{
    display: flex;
    flex-direction: row;
    height: 100%;
    
    
}

/* Customize scrollbar track */
::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
  }
  
  /* Customize scrollbar thumb (the draggable part) */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Color of the thumb */
    border-radius: 5px; /* Rounded corners of the thumb */
  }
  
  /* Customize scrollbar track when mouse hovers over it */
  ::-webkit-scrollbar-track:hover {
    background: #ddd; /* Color of the track on hover */
  }
  
  /* Customize scrollbar thumb when mouse hovers over it */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the thumb on hover */
  }
  
  /* Customize scrollbar corner (the intersection between vertical and horizontal scrollbar) */
  ::-webkit-scrollbar-corner {
    background: transparent; /* Background color of the corner */
  }
  
  /* Customize scrollbar track when scrollbar is active (being clicked or dragged) */
  ::-webkit-scrollbar-track:active {
    background: #ccc; /* Color of the track when active */
  }
  
  /* Customize scrollbar thumb when scrollbar is active */
  ::-webkit-scrollbar-thumb:active {
    background: #333; /* Color of the thumb when active */
  }
  
    /* Loading Animation */
    .loading-overlay {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
      color: #fff;
    }
  
    .loading-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;
  
    }
  
    .loading-icon-animation {
      position: static;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      height: 30px;
      width: 30px;
      border: 6px solid transparent;
      border-top-color: #47B8B2;
      animation: border-animation 2s linear infinite, background-animation 2s linear infinite;
      z-index: 0;
      /* Ensure animation is behind the icon */
    }
  
    .loading-text {
      font-family: "Roboto-Regular";
      font-size: 14px;
      margin-top: 20px;
      opacity: 0;
      /* Start with text hidden */
      animation: fadeIn .5s ease-in-out forwards;
      /* Apply the fade-in animation */
    }
  
    @keyframes border-animation {
      0% {
        transform: rotate(0deg);
      }
  
      100% {
        transform: rotate(360deg);
      }
    }
  
    @keyframes background-animation {
  
      0%,
      100% {
        background-color: transparent;
      }
  
      50% {
        background-color: #47B8B2;
      }
    }
  
  
    @keyframes fadeIn {
      0% {
        opacity: 0;
        /* Start with text hidden */
      }
  
      100% {
        opacity: 1;
        /* Fully visible at the end of the animation */
      }
    }