/* Locally Imported Fonts */
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../../components/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../../../components/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: medium;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../../components/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

@font-face {
    font-family: 'SSPro-Bold';
    src: url('../../../components/fonts/source-sans-pro/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

/* User Main Container */

.user-body-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
    overflow-x: hidden;
}

/* User Contents */

.user-homepage-main-contents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    height: 100%;
}

.user-homepage-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.user-body-limiter-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.user-body-limiter {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 10px 30px;
  height: 100%;
  width: 1440px;
} 

.user-body-container{
    width: 100%;
    height: 100%;
}

.user-homepage-contents{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

/* User Profile  */

.user-profile-contents {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 5px;
    overflow: hidden; 
    margin: 10px 0px;
  }
  
  .user-cover-pfp {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .user-cover {
    background-image: url("../../../assets/user-cover.png");
    background-position: top center;
    height: 180px;
    border-radius: 5px 5px 0px 0px;
    position: relative;
    z-index: 1;
  }
  
  .profile-main-det-container {
    display: flex;
    flex-direction: row; 
    height: 120px;
    width: 100%;
    position: relative; 
  }
  
  .user-picture-container {
    width: 200px;
    height: 200px;
    z-index: 2;
    display: flex;
    position: relative; 
    top: -110px; 
    left:65px;
  }
  

.user-picture {
    display: flex;
    width: 200px;
    height: 200px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    border-radius: 100%;
    border: 3px solid rgb(235, 235, 235);
    
}

.user-details-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left:100px; 
  }
  
  .user-det {
    display: flex;
    flex-direction: column;
    margin-top:10px;
    width: 100%;
    height: 100%;
  }
  
  .user-name {
    font-family: "SSPro-Bold";
    font-size: 28px;
    color: #000000;
    display: flex;
    align-items: center; 
    
  }
  
  .user-name i {
    color: #017364;
    font-size: 28px;
    margin-left: 5px;
  }
  
  .user-number {
    font-family: "Roboto-Light";
    font-size: 16px;
  }
  
  .user-course {
    font-family: "Roboto-Light";
    font-size: 16px;
  }
  
  .separator-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 8px;
    height: 90px;
    background-color: #D8D8D8;

  }
  
  .profile-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    
  }
  
  .profile-updater-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    background-color: #FFFF;
    color: #4D4D4D;
    margin-right: 10px;
    height: fit-content;
    width: 140px;
    font-family: "Roboto-Bold";
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #A4A2A2;
    transition: background-color 0.3s, color 0.3s; 
  }

  .profile-updater-btn:hover{
    background-color: #4D4D4D;
    color: #FFFFFF;
    border-radius: 5px;
  }
  
  .profile-updater-btn i {
    margin-left: 5px;
    font-size: 16px;
  }
  
  .profile-qr-btn i {
    margin-left: 5px;
    font-size: 16px;
  }
  
  .profile-qr-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    background-color: #47B8B2;
    color: #FFFFFF;
    height: fit-content;
    width: 130px;
    font-family: "Roboto-Bold";
    font-size: 14px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s; 
  }

  .profile-qr-btn:hover{
    background-color: #017364;;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 5px;
  }
  
  /* QR Popup */
  .homepage-popup-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of other content */
  }

  .user-profile-qr-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    background-color: #FFFFFF;
    width: auto;
    height: auto;
  }

  .home-popup-form-header{
    display: flex;
    padding: 25px 30px 2px 30px;
    align-items: center;
  }

  .home-header-icon{
   display: flex;
   color: #47B8B2;
   font-size: 22px;
   margin-right: 10px;
  }

  .home-header-txt{
   font-family: "Roboto-Bold";
   font-size: 20px;
   color:#292929;
  }


  .home-container-body{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    width: 480px;
    padding: 5px 35px 15px 35px;
  }

  .home-container-b-text{
   font-family: "Roboto-Regular";
   font-size: 13px;
   color: #4D4D4D;
  }

  .qrpop-header-icon{
   display: flex;
   font-size: 22px;
   margin-right: 5px;
   cursor: pointer;
 }

 .home-popup-footer{
   display: flex;
   padding: 3px 35px 30px 35px;
   align-items: center;
   justify-content: flex-end;
 }

 .home-cancel{
   font-family: "Roboto-Medium";
   padding: 15px 10px;
   font-size: 14px;
   width: 20px;
   padding: 8px 18px;
   cursor: pointer;
   border: 1px solid rgba(255, 255, 255, 0.2);
   transition: background-color 0.3s, color 0.3s, border-radius 0.3s
 }

 .home-cancel:hover{
   border: 1px solid rgba(0, 0, 0, 0.2);
   color: #292929;
   border-radius: 3px;
 }

 .home-submit{
   font-family: "Roboto-Medium";
   font-size: 14px;
   width: 20px;
   padding: 8px 18px;
   margin-left: 5px;
   border-radius: 3px;
   color: #f3f3f3;
   background-color: #47B8B2;
   cursor: pointer;
   transition: background-color 0.3s, color 0.3s, border-radius 0.3s
 }

 .home-submit:hover{
   background-color: #017364;
 }


  

  /* Log and Events */
  .log-events-containers{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin: 10px 0px;
  }

  .anno-carousel-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #e4e4e4;
  }

  .anno-carousel-limiter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1300px;
    height: fit-content;
    overflow: hidden; /* Ensure content doesn't overflow */
  }

  .anno-highlight-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 40px;
    height: fit-content;
    overflow: hidden; /* Ensure content doesn't overflow */
  }



  /* User Home Records Table*/
  .recent-logs-main-container{
    display: flex;
    width: 80%;
    height: 100%;
    overflow: hidden;
  }

  .homepage-attendance-records {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-right: 10px;
}

  .homepage-attendance-rec-mainbody {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
  }

  .homepage-attendance-rec-table-container {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #00000033;
}

  .homepage-attendance-rec-table-header-container {
    display: flex;
    align-items: center;
    background-color: #ffff;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #00000033;
    width: 100%;
    padding: 5px 0px;
    justify-content: space-between;
  }


  .view-more-rec-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: #FFFF;
    color: #4D4D4D;
    margin-right: 10px;
    height: fit-content;
    font-family: "Roboto-Medium";
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #A4A2A2;
    transition: background-color 0.3s, color 0.3s;
  }

  .view-more-rec-btn:hover{
    background-color: #4D4D4D;
    color: #ffff;
    font-family: "Roboto-Bold";
    border: 1px solid #A4A2A2;
  }

  .attendance-rec-container {
    width: 100%;
    height: 100%;
  }

  .homepage-table-footer-container{
    padding: 20px 20px; 
    background-color: #f3f3f3; 
  }

.events-side-main-container{
    display: flex;
    height: 100%;
    overflow: hidden;
}

/* User Footer  */

.user-footer {
    text-align: center;
    margin-top: auto;

}

.pp-linking-page{
    text-decoration: none;
}




@media (max-width: 375px){
  /* User Main Container */
  .user-body-container {
   display: block;
   min-height: auto;
   overflow-x: hidden;
}

/* User Contents */
.user-homepage-main-contents {
   justify-content: flex-start;
   padding: 20px;
}

.user-homepage-wrapper {
   align-items: flex-start;
   padding: 0;
}

.user-body-limiter-wrapper {
   padding: 0;
}

.user-home-body-limiter {
   padding: 10px;
   width: 100%;
}

/* User Profile */
div.user-profile-contents {
   margin: 10px;
   border: none;
   border-radius: 0;
   width: 360px;
}

.user-cover {
   height: 160px;
   border-radius: 0;
}

div.user-picture-container {
  
   height: 95px;
   top: -75px;
   left: 20px;
}

div.user-picture {
   width: 150px;
   height: 150px;
   border: 2px solid rgb(235, 235, 235);
}

div.user-details-container {
   margin: 10px;
   margin-left:80px;
   flex-direction:column;
   margin-left:20px;
}

span.user-name {
   font-size: 20px;
}

.user-name i {
   font-size: 24px;
}

.user-number,
.user-course {
   font-size: 13px;
}

.separator-line {
   display: none;
}

div.profile-buttons {
   flex-direction: row;
   justify-content: flex-start;
   margin-top:4px;
   margin-right:10px;
}

.profile-updater-btn,
.profile-qr-btn {
   width: 100%;
   margin-bottom: 10px;
}

/* QR Popup */
.qr-popup-container {
   padding: 10px;
}

.qr-container-user {
   padding: 15px 20px;
}

.qr-user-frame {
   border: 5px solid #D9D9D9;
}

/* Log and Events */
.log-events-containers {
   margin: 10px;
   flex-direction:column;
   justify-content: center;
   align-items:center;
}

.homepage-attendance-rec-mainbody {
   padding: 10px;
}

.homepage-attendance-rec-table-container {
   border-radius: 0;
   border: 1px solid #00000033;
}

div.homepage-attendance-rec-table-header-container {
   flex-direction: column;
  
}

.view-more-rec-btn {
   margin-top: 10px;
}

.user-footer {
   margin-top: 10px;
}
div.profile-main-det-container{
 justify-content:center;
 flex-direction:column;
 align-items:center;
 height: 250px;
}
div.separator-line{
 display:none;
}
div.eventlist-container{
 width: 360px;
 margin-right: 20px;

}

div.anno-pagination{
 font-size:25px;
}
.recent-logs-main-container{
 width:100%;
 margin-right:13px;
}
}
@media (max-width: 425px){
  /* User Main Container */
  .user-body-container {
   display: block;
   min-height: auto;
   overflow-x: hidden;
}

/* User Contents */
.user-homepage-main-contents {
   justify-content: flex-start;
   padding: 20px;
}

.user-homepage-wrapper {
   align-items: flex-start;
   padding: 0;
}

.user-body-limiter-wrapper {
   padding: 0;
}

.user-home-body-limiter {
   padding: 10px;
   width: 100%;
}

/* User Profile */
div.user-profile-contents {
   margin: 10px;
   border: none;
   border-radius: 0;
   width: 420px;
}

.user-cover {
   height: 160px;
   border-radius: 0;
}

div.user-picture-container {
  
   height: 95px;
   top: -75px;
   left: 20px;
}

div.user-picture {
   width: 150px;
   height: 150px;
   border: 2px solid rgb(235, 235, 235);
}

div.user-details-container {
   margin: 10px;
   margin-left:80px;
   flex-direction:column;
   margin-left: 75px;
}

span.user-name {
   font-size: 20px;
}

.user-name i {
   font-size: 24px;
}

.user-number,
.user-course {
   font-size: 13px;
}

.separator-line {
   display: none;
}

div.profile-buttons {
   flex-direction: row;
   justify-content: flex-start;
   margin-top:4px;
   margin-right:10px;
}

.profile-updater-btn,
.profile-qr-btn {
   width: 100%;
   margin-bottom: 10px;
}

/* QR Popup */
.qr-popup-container {
   padding: 10px;
}

.qr-container-user {
   padding: 15px 20px;
}

.qr-user-frame {
   border: 5px solid #D9D9D9;
}

/* Log and Events */
.log-events-containers {
   margin: 10px;
   flex-direction:column;
   justify-content: center;
   align-items:center;
}

.homepage-attendance-rec-mainbody {
   padding: 10px;
}

.homepage-attendance-rec-table-container {
   border-radius: 0;
   border: 1px solid #00000033;
}

div.homepage-attendance-rec-table-header-container {
   flex-direction: column;
  
}

.view-more-rec-btn {
   margin-top: 10px;
}

.user-footer {
   margin-top: 10px;
}
div.profile-main-det-container{
 justify-content:center;
 flex-direction:column;
 align-items:center;
 height: 250px;
}
div.separator-line{
 display:none;
}
div.eventlist-container{
 width: 360px;
 margin-right: 0px;

}

div.anno-pagination{
 font-size:25px;
}
.recent-logs-main-container{
 width:100%;
 margin-right: -8px;
}
}
@media (max-width: 768px){
  /* User Main Container */
  .user-body-container {
   display: block;
   min-height: auto;
   overflow-x: hidden;
}

/* User Contents */
.user-homepage-main-contents {
   justify-content: flex-start;
   padding: 20px;
}

.user-homepage-wrapper {
   align-items: flex-start;
   padding: 0;
}

.user-body-limiter-wrapper {
   padding: 0;
}

.user-home-body-limiter {
   padding: 10px;
   width: 100%;
}

/* User Profile */
div.user-profile-contents {
   margin: 10px;
   border: none;
   border-radius: 0;
   width: 760px;
}

.user-cover {
   height: 160px;
   border-radius: 0;
}

div.user-picture-container {
  
   height: 95px;
   top: -75px;
   left: 20px;
}

div.user-picture {
   width: 150px;
   height: 150px;
   border: 2px solid rgb(235, 235, 235);
}

div.user-details-container {
   margin: 10px;
   margin-left:80px;
   flex-direction:column;
   margin-left: 436px;
}

span.user-name {
   font-size: 20px;
}

.user-name i {
   font-size: 24px;
}

.user-number,
.user-course {
   font-size: 13px;
}

.separator-line {
   display: none;
}

div.profile-buttons {
   flex-direction: row;
   justify-content: flex-start;
   margin-top:4px;
   margin-right:10px;
}

.profile-updater-btn,
.profile-qr-btn {
   width: 100%;
   margin-bottom: 10px;
}

/* QR Popup */
.qr-popup-container {
   padding: 10px;
}

.qr-container-user {
   padding: 15px 20px;
}

.qr-user-frame {
   border: 5px solid #D9D9D9;
}

/* Log and Events */
.log-events-containers {
   margin: 10px;
   flex-direction:column;
   justify-content: center;
   align-items:center;
}

.homepage-attendance-rec-mainbody {
   padding: 10px;
}

.homepage-attendance-rec-table-container {
   border-radius: 0;
   border: 1px solid #00000033;
}

div.homepage-attendance-rec-table-header-container {
   flex-direction: column;
  
}

.view-more-rec-btn {
   margin-top: 10px;
}

.user-footer {
   margin-top: 10px;
}
div.profile-main-det-container{
 justify-content:center;
 flex-direction:column;
 align-items:center;
 height: 250px;
}
div.separator-line{
 display:none;
}
div.eventlist-container{
 width: 360px;
 margin-right: 0px;

}

div.anno-pagination{
 font-size:25px;
}
.recent-logs-main-container{
 width:100%;
 margin-right: -8px;
}
}



/* Loading Animation */
.loading-overlay {
   display: flex;
   flex-direction: column;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.5);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 9999;
   color: #fff;
}

.loading-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   position: relative;

}

.loading-icon-animation {
   position: static;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   border-radius: 100%;
   height: 30px;
   width: 30px;
   border: 6px solid transparent;
   border-top-color: #47B8B2;
   animation: border-animation 2s linear infinite, background-animation 2s linear infinite;
   z-index: 0;
   /* Ensure animation is behind the icon */
}

.loading-text {
   font-family: "Roboto-Regular";
   font-size: 14px;
   margin-top: 20px;
   opacity: 0;
   /* Start with text hidden */
   animation: fadeIn .5s ease-in-out forwards;
   /* Apply the fade-in animation */
}

@keyframes border-animation {
   0% {
      transform: rotate(0deg);
   }

   100% {
      transform: rotate(360deg);
   }
}

@keyframes background-animation {

   0%,
   100% {
      background-color: transparent;
   }

   50% {
      background-color: #47B8B2;
   }
}


@keyframes fadeIn {
   0% {
      opacity: 0;
      /* Start with text hidden */
   }

   100% {
      opacity: 1;
      /* Fully visible at the end of the animation */
   }
}