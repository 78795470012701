/* Locally Imported Fonts */
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../../components/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../../../components/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: medium;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../../components/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

@font-face {
    font-family: 'SSPro-Bold';
    src: url('../../../components/fonts/source-sans-pro/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

/* Main Container */
.admin-post-body{
    display: flex;
    justify-content: center;
}

.admin-annopost-contents{
    display: flex;
    justify-content: center;
    padding: 15px;
    width: 100%;
   
}

/* Announcement Details Container */
.anno-postview-container-wrapper{
    display: flex;
    justify-content: center;
    height: fit-content;
    overflow-y: auto;
}

.annoview-post-main-container{
    margin: 15px;
    padding: 15px;
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 1px solid #00000033;
    overflow-y: auto;
}


/* Announcement Details */

.anno-post-contents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    width:700px;
}
.anno-picture-container{
    display: flex;
    justify-content: center;
    width:auto;
    height: auto;
    border-radius: 10px;
}
.anno-main-picture{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width:700px;
    height:400px;
    border-radius: 10px;
    background-size: cover;
}



/* Announcement Main Body */
.annodet-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.annodetails-container{
    display: flex;
    flex-direction:column;
    justify-content: center;
    width:700px;
    padding: 5px;
}

.anno-main-title-txt{
    font-family: "SSPro-Bold";
    font-size: 30px;
    color: #181818;
}

.anno-poster-details{
    font-family: "Roboto-Medium";
    font-size: 14px;
    color: #181818;
    
}

.anno-poster{
    margin: 5px 0px;
    margin-right: 15px;
}

.anno-post-det-main-container{
    margin-top: 5px;
    text-align: justify;
    padding-bottom: 40px;
}

.anno-post-det{
    font-family: "Roboto-Regular";
    color: #6C6C6C;
    text-align: center;
}

