/* Locally Imported Fonts */
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../../components/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../../../components/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: medium;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../../components/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

@font-face {
    font-family: 'SSPro-Bold';
    src: url('../../../components/fonts/source-sans-pro/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}

/* User Main Container */

.user-body-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
    overflow-x: hidden;
}

/* Lower Topbar  */

.lower-topbar-wrapper{
  display: flex;
  justify-content: center;
  background-color: #47B8B2;

}

.lower-topbar{
  font-family: "Roboto-Regular";
  font-size: 14px;
  color: #FFFFFF;
  padding: 10px;
  display: flex;
  flex-direction: row;
  padding: 15px 35px;
  height: 100%;
  width:1440px;
}

.lower-topbar-text{
  padding: 0px 5px;
}

.top-linking-page{
  text-decoration: none;
}

.active-linking{
  color: #ffff;
}

.active-linking:hover{
  color: #ffce74;
}

/* User Contents */

.user-main-contents{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:100%;
    height: 100%;
}
.user-body-limiter {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 10px 30px;
    height: 100%;
    width:1440px;
}

.user-homepage-contents{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
/* User Profile  */


.user-profile-contents {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 5px;
    overflow: hidden; /* Ensure content doesn't overflow */
  }
  
  .user-cover-pfp {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .user-cover {
    background-image: url("../../../assets/user-cover.png");
    background-position: top center;
    height: 180px;
    border-radius: 5px 5px 0px 0px;
    position: relative;
    z-index: 1;
  }
  
  .profile-main-det-container {
    display: flex;
    flex-direction: row; 
    height: 120px;
    width: 100%;
    position: relative; 
  }
  
  .user-picture-container {
    width: 200px;
    height: 200px;
    z-index: 2;
    display: flex;
    position: relative; 
    top: -110px; 
    left:65px;
  }
  

.user-picture {
    display: flex;
    width: 200px;
    height: 200px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    border-radius: 100%;
    border: 3px solid rgb(235, 235, 235);
    
}

.user-details-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left:100px; 
  }
  
  .user-det {
    display: flex;
    flex-direction: column;
    margin-top:10px;
    width: 100%;
    height: 100%;
  }
  
  .user-name {
    font-family: "SSPro-Bold";
    font-size: 28px;
    color: #000000;
    display: flex;
    align-items: center; 
    
  }
  
  .user-name i {
    color: #017364;
    font-size: 28px;
    margin-left: 5px;
  }
  
  .user-number {
    font-family: "Roboto-Light";
    font-size: 16px;
  }
  
  .user-course {
    font-family: "Roboto-Light";
    font-size: 16px;
  }
  
  .separator-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 8px;
    height: 90px;
    background-color: #D8D8D8;

  }
  
  .profile-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    
  }
  
  .profile-updater-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    background-color: #FFFF;
    color: #4D4D4D;
    margin-right: 10px;
    height: fit-content;
    width: 140px;
    font-family: "Roboto-Bold";
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #A4A2A2;
    transition: background-color 0.3s, color 0.3s; 
  }

  .profile-updater-btn:hover{
    background-color: #4D4D4D;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .profile-updater-btn i {
    margin-left: 5px;
    font-size: 16px;
  }
  
  .profile-qr-btn i {
    margin-left: 5px;
    font-size: 16px;
  }
  
  .profile-qr-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    background-color: #47B8B2;
    color: #FFFFFF;
    height: fit-content;
    width: 130px;
    font-family: "Roboto-Bold";
    font-size: 14px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s; 
  }

  .profile-qr-btn:hover{
    background-color: #017364;;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 5px;
  }

  .user-profile-qr-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    background-color: #FFFF;
    color: #4D4D4D;
    margin-top: 5px;
    height: fit-content;
    width: 140px;
    font-family: "Roboto-Bold";
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #A4A2A2;
    transition: background-color 0.3s, color 0.3s; 
  }

  .user-profile-qr-btn:hover{
    background-color: #4D4D4D;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .user-profile-qr-btn i {
    margin-left: 5px;
    font-size: 16px;
  }

  
/* User Footer  */

.user-footer {
    text-align: center;
    margin-top: auto;

}

.pp-linking-page{
    text-decoration: none;
}

/* Profile Update */
.user-attendance-records {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-right: 10px;
    
}

.user-update-rec-mainbody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
}

.user-update-rec-table-container {
  display: flex;
  flex-direction: column;
    height: 610px;
    border-radius: 5px;
    border: 1px solid #00000033;
    overflow: hidden;
}

.profile-update-header{
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  align-items: center;
  border-bottom: 1px solid #00000033
}

.left-header-update{
    display: flex;
    width: 500px;
}

.user-attendance-rec-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #525252;
    padding: 5px 15px;
    width: fit-content;
}

/* Switch Tabs */
.user-form-switching{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  align-items: center;
  color: #525252;
}

.basic-information-tab{
  font-family: "Roboto-Medium";
  font-size: 14px;
  padding: 10px 20px;
  border-bottom: 4px solid #FFFFFF;
  transition: all 0.2s ease;
}

.basic-information-tab:hover{
  font-family: "Roboto-Medium";
  font-size: 14px;
  padding: 15px 20px 15px 20px;
  border-bottom: 4px solid #47B8B2;
  transition: all 0.2s ease;
  cursor: pointer;
  background-color: #fcfcfc;
  color: #000000;
}

.account-details-tab{
  font-family: "Roboto-Medium";
  font-size: 14px;
  padding: 10px 20px;
  border-bottom: 4px solid #FFFFFF;
  transition: all 0.2s ease;
}

.account-details-tab:hover{
  font-family: "Roboto-Medium";
  font-size: 14px;
  padding: 15px 20px 15px 20px;
  border-bottom: 4px solid #47B8B2;
  transition: all 0.2s ease;
  cursor: pointer;
  background-color: #fcfcfc;
  color: #000000;
}

.change-password-tab{
  font-family: "Roboto-Medium";
  font-size: 14px;
  padding: 10px 20px;
  border-bottom: 4px solid #FFFFFF;
  transition: all 0.2s ease;
}

.change-password-tab:hover{
  font-family: "Roboto-Medium";
  font-size: 14px;
  padding: 15px 20px 15px 20px;
  border-bottom: 4px solid #47B8B2;
  transition: all 0.2s ease;
  cursor: pointer;
  background-color: #fcfcfc;
  color: #000000;
}

.change-avatar-tab{
  font-family: "Roboto-Medium";
  font-size: 14px;
  padding: 10px 20px;
  border-bottom: 4px solid #FFFFFF;
  transition: all 0.2s ease;
}

.change-avatar-tab:hover{
  font-family: "Roboto-Medium";
  font-size: 14px;
  padding: 15px 20px 15px 20px;
  border-bottom: 4px solid #47B8B2;
  transition: all 0.2s ease;
  cursor: pointer;
  background-color: #fcfcfc;
  color: #000000;
}

/* User Subheader */
.user-profile-update-subheader{
  display: flex;
  justify-content: center;
  font-family: "Roboto-Medium";
  font-size: 16px;
  background-color: #7C7A7A;
  color: #FFFF;
  padding: 8px;
  width: 100%;
}

/* User Form Fields */

/* User Basic Information Form Fields */
.update-basic-info-ff-container,
.update-address-info-ff-container {
  display: flex;  
  flex-direction: column;
  padding: 15px 100px;

}

.update-emergency-information-ff-container {
    display: flex;
    padding: 15px 100px;

}

.update-account-info-ff-container {
  display: flex;
  flex-direction: column;
  padding: 15px 100px 15px 50px;
}


.update-profile-form{
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}


.update-pass-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.update-avatar-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.10);
}

.update-account-form{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  width: 100%;
  height: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.10);
}

.update-avatar-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.update-pfp-det{
  display: flex;
  justify-content: center;
  font-family: "Roboto-Medium";
  font-size: 16px;
  padding:10px 0px ;

}

.main-user-det-container,
.main-address-det-container,
.main-emergency-det-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-account-det-container{
  display: flex;
  flex-direction: column;
}

.profile-update-container {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

/* Form Field CSS - General */
.update-info-ff-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.update-info-ff-2,
.update-info-ff-3,
.update-info-ff-4,
.update-info-ff-5 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.update-info-ff-6{
  display: flex;
  width: 100%;
}

.update-info-ff-7,
.update-info-ff-8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:380px;
}

.update-info-form-field input,
.update-info-form-field select {
    font-size: 12px;
    font-family: "Roboto-Regular";
    background-color: #EFEFEF;
    border-radius: 5px;
    border: none;
    padding: 10px;
    margin-right: 5px;
}

.update-info-form-field-sn input {
    font-size: 12px;
    font-family: "Roboto-Regular";
    background-color: #EFEFEF;
    border-radius: 5px;
    border: none;
    padding: 10px;
    margin-right: 5px;
    width: 100px;
}
/* Drop Down */

.update-info-form-field option {
    font-size: 12px;
    font-family: "Roboto-Regular";
    color: #525252;
    background-color: #EFEFEF;
    border: none;
    margin-right: 5px;
    height: 30px;
}

.update-info-form-field,.update-info-form-field-sn,
.update-info-form-field-ext label {
    font-family: "Roboto-Medium";
    font-size: 12px;
    padding: 2.5px 0;
    color: #525252;
}

.update-info-form-field {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.update-info-form-field input:focus {
    outline: 1px solid #47B8B2;
    border: 1px solid #47B8B2;
}

.update-info-form-field select:focus {
    outline: 1px solid #47B8B2;
    border: 1px solid #47B8B2;
}

.update-info-form-field-ext {
    display: flex;
    flex-direction: column;
    width: 100px;
}

.update-info-form-field-ext input {
    font-size: 12px;
    font-family: "Roboto-Regular";
    background-color: #EFEFEF;
    border-radius: 5px;
    border: none;
    padding: 10px;
    margin-right: 5px;
}

.update-info-form-field-ext input:focus {
    outline: 1px solid #47B8B2;
    border: 1px solid #47B8B2;
}

.ast {
    font-size: 16px;
    color: rgb(190, 50, 50);
}

.important-ff {
    display: flex;
    align-items: center;
}

.important-ff p {
    margin: 0;
    padding-left: 2px;
}

.important-ff-none label{
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

/* User Tabs */
.basic-information-tab-form{
  display:flex;
  height: 610px;
  overflow-y: auto;
  overflow-x: hidden;
}

.basic-information-tab-form.tab-transition-left {
  transform: translateX(-100%);
}

.account-details-tab-form.tab-transition-right {
  transform: translateX(100%);
}

.submit-form-footer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.10);
  padding: 10px 50px
}

.account-submit-form-footer {
  display: flex;
  flex-direction: row;
  padding: 0px 105px;
  justify-content: flex-end;
  align-content: center;
}

.update-user-form-btn{
  font-family: "Roboto-Medium";
  font-size: 14px;
  color: #FFFF;
  padding: 10px 20px;
  background-color: #409B54;
  border: none;
  border-radius: 5px;
}

.update-user-form-btn:hover{
  background-color: #276134;
  cursor: pointer;
}

.account-update-user-form-btn{
  font-family: "Roboto-Medium";
  font-size: 14px;
  color: #FFFF;
  padding: 10px 20px;
  background-color: #409B54;
  border: none;
  border-radius: 5px;
}

.account-update-user-form-btn:hover{
  background-color: #276134;
  cursor: pointer;
}

.password-submit-form-footer {
  display: flex;
  flex-direction: row;
  padding: 10px 65px;
  justify-content: flex-end;
  width: 100%;
}


/* Style the custom checkbox container */
.data-privacy{
  margin-right: 10px;
}

.data-priv {
  display: flex;
  align-items: center;
  font-family: "Roboto-Regular";
  font-size: 14px;
  color: #333;
  cursor: pointer; 
}

.data-priv-text{
  font-family: "Roboto-Regular";
  font-size: 14px;
  color: #333;
}

.data-priv-text:hover{
  font-family: "Roboto-Regular";
  font-size: 14px;
  color: #47B8B2;
  cursor: pointer;
}

.data-priv .cb[type="checkbox"] {
  display: none; 
}


.data-priv .cm {
  position: relative;
  padding: 7px;
  border: 2px solid #dbdbdb; 
  background-color: transparent;
  margin-right: 10px; 
}

/* Style the custom checkbox when checked */ 
.data-priv .cb[type="checkbox"]:checked + .cm {
  background-color: #47B8B2;
  border-color: #47B8B2;
}

/* Add a checkmark icon when the checkbox is checked */
.data-priv .cb[type="checkbox"]:checked + .cm::before {
  content: "\2713"; /* Unicode checkmark character (✓) */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
  border-radius: 5px;
}

.fps{
  text-decoration: none;
  color: #47B8B2;
}


/* Modal Overlay */
.qr-popup-overlay{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}


/* Main Container */
.user-main-popup-form-container {
  display: flex;
  justify-content: center;
  height: auto;
}

.popup-form-container {
  display: flex;
  flex-direction: column;
  width:auto;
  height: auto;
  border-radius: 5px;
  border: 1px solid #00000033;
  background-color: #EFEFEF;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #00000033;
  max-height: 100%; 
}



/* Popup Form - Edit Header */
.edit-popup-form-header {
  display: flex;
  background-color:#F5CA62;;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #00000033;
  width: 100%;
}

.view-popup-form-header {
  display: flex;
  background-color: #47B8B2;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #00000033;
  width: 100%;
}

.edit-popup-title-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #7a7878;
  padding: 5px 15px;
  width: 100%;
}

.epop-header-icon {
  padding: 10px 0px;
  margin: 0;
}

.epop-header-icon i {
  font-size: 26px;
  color: white;
  cursor: pointer;
}

.epop-header-title p {
  font-size: 20px;
  font-family: 'Roboto-Bold';
  margin: 0;
}

.staff-det-container{
  margin:0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 450px;
  width: 100%;
  align-items: center;
}

.view-staff-det-container{
  margin:0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
  width: 100%;
  align-items: center;
}

.user-details-popup-container {
  display: flex;
  flex-direction: row;
}

/* Terms and Conditions Popup */
.cond-details{
  display: flex;
  flex-direction: column;
  padding: 15px;
  height: 300px;
  overflow-y: auto;
}

.cond-main-header{
  display: flex;
  width: 100%;
  padding: 3px 0px;
  font-family: "SSPro-Bold";
  font-size: 24px;
  justify-content: center;
  color: #353535;
  border-bottom: solid 1px #47B8B2
}

.cond-section{
  display: flex;
  flex-direction: column;
  padding: 5px 10px;

}

.cond-header{
  font-family: "Roboto-Bold";
  font-size: 16px;
  margin: 5px 0px;
  color: #353535;
}

.cond-det{
  font-family: "Roboto-Regular";
  font-size: 14px;
  text-align: justify;
  margin: 2px 0px;
}


.obj-details{
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 450px;
  height: 200px;
  overflow-y: auto;
}


/* Message Popup */
.system-message-popup{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
}

.system-message-header{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 10px 0px 0px;
}

.system-message-close i{
  font-size: 18px;
  color: #4D4D4D;
  cursor: pointer;
}

.system-message-icon i{
  font-size: 32px;
  color: #47B8B2;
  margin-bottom: 10px;
}

.system-message-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 15px 30px 15px;
  width: 300px;
}

.system-txt{
  font-family: "Roboto-Regular";
  font-size: 14px;
  text-align: center;
}



/*Pass Strength*/

form .indicator {
  height: 8px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
}

form .indicator span {
  position: relative;
  height: 100%;
  width: 100%;
  background: lightgrey;
  border-radius: 5px;
}

form .indicator span:nth-child(2) {
  margin: 0 3px;
}

form .indicator span.active:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;

}

.indicator span.weak:before {
  background-color: #C76565;
}

.indicator span.medium:before {
  background-color: #F5CA62;
}

.indicator span.strong:before {
  background-color: #409B54;
}

.password-mismatch-cont {
  display: flex;
  align-items: center;
  font-family: "Roboto-Regular";
  font-size: 12px;
  color: #C76565;
  height: 10px;
  margin-top: 5px;
  width: 100%;
}


/*MEDIA QUERY*/

@media (max-width: 375px){
  .user-body-limiter{
    width:100%;
    flex-direction:column;
    align-items:center;
  }
  div.update-info-ff-1,.update-info-ff-2, .update-info-ff-3, .update-info-ff-4, .update-info-ff-5, .update-info-ff-6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
}


  .update-basic-info-ff-container, .update-address-info-ff-container{
    margin-left: -45px;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}
.update-account-form{
  flex-direction: column;
}
.left-header-update{
  justify-content:center;
}
 /* .update-account-info-ff-container{
  margin-left:-60px;
} 
.main-account-det-container {
  margin-left:60px;
/* } */

.important-ff-none label{
  margin-left:25px;
} 



.update-info-form-field input, .update-info-form-field select{
margin-left: 20px;
    width: 100%;
}
.account-submit-form-footer{
  justify-content:center;
}
.profile-update-header{
  flex-direction: column;
}

}


@media (max-width: 425px){
  .user-body-limiter{
    width:100%;
    flex-direction:column;
    align-items:center;
  }
  div.update-info-ff-1,.update-info-ff-2, .update-info-ff-3, .update-info-ff-4, .update-info-ff-5, .update-info-ff-6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
}



 .update-basic-info-ff-container, .update-address-info-ff-container{
    margin-left: -45px;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}
.update-account-form{
  flex-direction: column;
}

.important-ff-none label{
  margin-left:25px;
} 

.update-info-form-field input, .update-info-form-field select{
margin-left: 20px;
    width: 100%;
}
.account-submit-form-footer{
  justify-content:center;
}
}


@media (max-width: 768px){
  div.user-body-limiter{
    width:100%;
    flex-direction:column;
    align-items:center;
  }

  .update-basic-info-ff-container, .update-address-info-ff-container{
    margin-left: -45px;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}
  }

