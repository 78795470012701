/* Locally Imported Fonts */
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../../components/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../../../components/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: medium;
    font-size: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../../components/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;
}
/* Main Container */
.qr-popup-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.anno-main-popup-form-container {
    display: flex;
    justify-content: center;
    height: auto;
}

.anno-popup-form-container {
    display: flex;
    flex-direction: column;
    width:850px;
    height: auto;
    border-radius: 5px;
    border: 1px solid #00000033;
    background-color: #EFEFEF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #00000033;
    max-height: 100%; /* Adjust the maximum height as needed */

}



/* Popup Form - Edit Header */
.anno-edit-popup-form-header {
    display: flex;
    background-color: #47B8B2;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #00000033;
    width: 100%;
}

.anno-edit-popup-title-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #7a7878;
    padding: 5px 15px;
    width: 100%;
}

.epop-header-icon {
    padding: 10px 0px;
    margin: 0;
}

.epop-header-icon i {
    font-size: 26px;
    color: white;
    cursor: pointer;
}

.epop-header-title p {
    font-size: 20px;
    font-family: 'Roboto-Bold';
    margin: 0;
}

.view-staff-det-container{
    margin:0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    width: 100%;
    align-items: center;
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: none; }