/* Locally Imported Fonts */
@font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: light;
    font-size: normal;
}

@font-face {
    font-family: 'SSPro-Bold';
    src: url('../fonts/source-sans-pro/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-size: normal;

}
.staff-topbar-main{
    overflow: hidden;
    width: 100%;
}
.staff-topbar-cont-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.staff-topbar-cont{
    display: flex;
    justify-content: space-between;
    height: 70px;
    width: 1460px;
    padding: 0px 10px;
    background-color: #FFFFFF;
    color: #404040;
}


/*Left container - Expand Button and Header */

.left-tb{
    display: flex;
    align-items: center;
    margin-left: 20px;

}

.SHA-logo{
    padding: 0px 15px 0px 0px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SHA-logo img{   
    width: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 2px solid #dfdfdf;
    
}

.topbar-name{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.staff-page-name{
    font-family: "SSPro-Bold";
    font-size: 20px;
    margin: 0px;
    color: #181818;
}

.time-date{
    font-family: "Roboto-Light";
    font-size: 12px;
    margin: 0px;
    color: #181818;
}

/* Right container - Logout Button */
.right-tb {
    display: flex;
    justify-content: flex-end;
    
}

.page-buttons-container{
    display: flex;
    font-family: "Roboto-Bold";
    font-size: 16px;
    color: #181818;
    justify-content: center;
    align-items: center;
    margin-right:20px;
    
}


.homepage-btn, .records-btn{
    padding: 10px 35px;
    color: #181818;
    border-bottom: 4px solid #FFFFFF;
    transition: all 0.2s ease;
}

.homepage-btn:hover, .records-btn:hover{
    padding: 15px 35px;
    color: #181818;
    background-color: #fcfcfc;
    border-bottom: 4px solid #47B8B2;
    cursor:pointer;
}

.nav-linking-page{
    text-decoration: none;
    display: flex;
    width:100%;
}


/* Logout Button */
.logout-container {
    display: flex;
    margin-right: 15px;
    padding: 15px;
    align-items: center;
    justify-content: center;
    width:40px;
}

.logout-container:hover {
    margin-right: 15px;
    padding: 10px 15px;
    border-radius: 100%;
}

.bx-log-out {
    color: #404040;
    transform: scaleX(-1);
    font-size: 28px;
    margin-right: 5px;
    cursor: pointer;
}

/* Log Out Confirmation */

.logout-modal{
    display: flex;
    justify-content: center;
    height: auto;
}

.logout-modal-container{
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    width:300px;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    border-radius: 5px;
    border: none;
    height: auto;
    background-color: #ffffff;
}

.logout-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 20px 10px 20px;
}

.logout-icon{
    color: #47B8B2;
    font-size: 36px;
}

.logout-title{
    font-family: "Roboto-Medium";
    color: #181818;
    font-size:16px;
}

.logout-selection-container{
    display: flex;
    justify-content: center;
    font-family: "Roboto-Medium";
    width:100%;
    height: auto;
    border-radius: 0px 0px 5px 5px;
    font-size: 12px;
}

.cancel-logout-btn{
    display: flex;
    justify-content: center;
    color: #fff;
    background-color: #757575;
    padding:10px;
    border-radius: 0px 0px 0px 5px;
}

.cancel-logout-btn:hover{
    cursor:pointer;
    background-color: #404040;
    font-family: "Roboto-Medium";

}

.confirm-logout-btn{
    display: flex;
    justify-content: center;
    color: #fff;
    background-color: #47B8B2;
    width:130px;
    padding:10px;
    border: 1px solid #47B8B2;
    border-radius: 0px 0px 5px 0px;
}

.confirm-logout-btn:hover{
    cursor:pointer;
    color: #404040;
    font-family: "Roboto-Medium";
    background-color: #F5F5F5;
    border: 1px solid #47B8B2;
    border-radius: 0px 0px 5px 0px;
}

.qr-popup-overlay{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}
/* Hamburger menu icon for mobile */
.hamburger-menu {
    display: none; /* Hide by default for desktop */
  }
  
  /* Mobile menu for smaller screens */
  .mobile-menu {
    display: none; /* Hide by default for desktop */
    left: -100%; /* Initially off-screen to the left */
    position: fixed;
    background-color: #ebebeb;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    transition: left 1s ease-in-out; /* Apply the transition to the left property */
  }
  
  /* Add a class to trigger the animation */
  .menu-open {
    left: 0; /* Slide it to the right when open */
  }
  
  @media (max-width: 768px) {
    .page-buttons-container {
      display: none; /* Hide navigation links for mobile */
    }
    span.logout-container {
      display: none;
    }
  
    .hamburger-menu {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .hamburger-menu i {
      font-size: 32px;
      color: #404040;
      padding: 15px 20px;
    }
  
    .hamburger-menu i:hover {
      font-size: 32px;
      color: #47B8B2;
      padding: 15px 20px;
      cursor: pointer;
    }
  
    .mobile-menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: fixed;
      background-color: #ebebeb;
      z-index: 10000;
      width: 100%;
      height: 100%;
      top: 0;
      transition: left 1s ease-in-out; /* Apply the transition to the left property */
    }
  
    /* Add a class to trigger the animation */
    .menu-open {
      left: 0; /* Slide it to the right when open */
    }
  
    .topbar-navigation {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-decoration: none;
      color: #404040;
      padding: 15px;
      border: solid 1px none;
    }
  
    .nav-text {
      font-family: "Roboto-Medium";
      font-size: 22px;
    }
  
    .nav-icon {
      font-family: "Roboto-Medium";
      font-size: 24px;
      margin-right: 15px;
    }
  
    .topbar-navigation:hover {
      text-decoration: none;
      color: #353535;
      padding: 15px;
      border: solid 1px #47B8B2;
      background-color: #f1f1f1;
    }
  }
  @media (max-width: 425px) {
    .right-tb {
        display: flex;
        justify-content: flex-end;
        margin-right: 0px;
    }
    .staff-page-name {
        font-family: "SSPro-Bold";
        font-size: 16px;
        margin: 0px;
        color: #181818;
    }
    .time-date {
        font-family: "Roboto-Light";
        font-size: 12px;
        margin: 0px;
        color: #181818;
    }

    .staff-topbar-cont {
        padding: 0px 0px;
    }

  }

  